import React from 'react'
import PropTypes from 'prop-types'
import shuffle from 'lodash/shuffle'

import Layout from 'components/layout'
import SEO from 'components/seo'
import FeaturedJobs from 'components/FeaturedJobs'

import Hero from './Hero'
import Intro from './Intro'
import Video from './Video'
import Categories from './Categories'
import Companies from './Companies'

const Home = ({ pageContext: { featuredJobs } }) => {
  return (
    <Layout light>
      <SEO
        title="Design, Tech &amp; Product Recruitment"
        description="Looking for something a bit different? Let us find you your best job yet."
      />
      <Hero />
      <Intro />
      <Companies />
      <Video />
      <Categories />
      <FeaturedJobs jobs={shuffle(featuredJobs).slice(0, 3)} curve />
    </Layout>
  )
}

Home.propTypes = {
  pageContext: PropTypes.shape({
    featuredJobs: PropTypes.array.isRequired,
  }).isRequired,
}

export default Home
