import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { screen } from 'styles/helpers/responsive'

import { H, Grid, Wrapper } from 'components/common'
import { COLOR } from 'styles/tokens'
import Image from 'components/Image'
import CategoryList from 'components/CategoryList'

const StyledImageGridItem = styled(Grid.Item)`
  ${screen.m`display: none;`}
`

const Categories = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "ajay-and-team-at-whiteboard.jpg" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <Wrapper background={COLOR.BACKGROUND.PALE}>
      <Wrapper container>
        <H size="L" margin={[0, 0, 'L']} marginFromM={[0, 0, 'XL']} centered>
          What we recruit for
        </H>
        <Grid gapFromM="XL">
          <Grid.Item spanFromL={6} style={{ alignSelf: 'center' }}>
            <CategoryList />
          </Grid.Item>
          <StyledImageGridItem spanFromL={6}>
            <Image sizes={{ ...image.childImageSharp.fluid }} />
          </StyledImageGridItem>
        </Grid>
      </Wrapper>
    </Wrapper>
  )
}

export default Categories
