import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'

import PATHS from 'routes/paths'

import { Button, H, Grid, Wrapper } from 'components/common'
import { COLOR } from 'styles/tokens'
import Image from 'components/Image'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.GREY.DARK};
  color: ${COLOR.WHITE};
`

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const Intro = () => {
  const { imageOne } = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "girl-with-green-hair-laughing.jpg" }
      ) {
        ...fluidImage
      }
    }
  `)

  return (
    <StyledWrapper>
      <Wrapper container>
        <Grid gapFromM="XL">
          <Grid.Item spanFromM={5}>
            <H size="L" margin={[0, 0, 'L']} color={COLOR.ACCENT.PRIMARY}>
              We match digital experts to world-class clients
            </H>
            <Wrapper margin={[0, 0, 'XL']}>
              <p>
                Finding our clients the best fit never means a quick fix. It’s
                important for us to get to know candidates and the businesses
                they’ll work for to make sure we’ve discovered the very best
                person for the job.
              </p>
            </Wrapper>
            <Button.Primary tag={Link} wide to={PATHS.EMPLOYERS} stretchMobile>
              Employer’s guide
            </Button.Primary>
          </Grid.Item>
          <Grid.Item spanFromM={6} startColumnFromM={7}>
            <Image
              sizes={{ ...imageOne.childImageSharp.fluid, aspectRatio: 6 / 5 }}
            />
          </Grid.Item>
        </Grid>
      </Wrapper>
    </StyledWrapper>
  )
}

export default Intro
