import React from 'react'
import styled from 'styled-components'
import { screenMax } from 'styles/helpers/responsive'

import { COLOR, GTR } from 'styles/tokens'

const StyledBullet = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: ${COLOR.ACCENT.PRIMARY};
  margin: 0 ${GTR.S};
  transform: translateY(-2px);

  ${screenMax.m`
    margin: 0 ${GTR.XS};
  `}
`

const Bullet = () => {
  return <StyledBullet />
}

export default Bullet
