import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { screenMin, screenMax } from 'styles/helpers/responsive'

import PATHS from 'routes/paths'
import { COLOR, FONT, GTR } from 'styles/tokens'

import { H, Button, Grid, Wrapper } from 'components/common'
import FullVideo from './FullVideo'

const StyledP = styled.p`
  font-size: 24px;
  margin-bottom: ${GTR.L};
  line-height: 1.5;

  ${screenMax.s`
    font-size: ${FONT.SIZE.L};
  `}
`

const StyledHeroWrapper = styled(Wrapper)`
  ${screenMin.l`
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background-color: ${COLOR.ACCENT.PRIMARY};
      top: -114px;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -2;
    }

    &:after {
      content: '';
      position: absolute;
      background-color: ${COLOR.WHITE};
      height: 2000px;
      width: 2000px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-15%, -75%);
    }
  `}
`

const Home = () => {
  return (
    <StyledHeroWrapper>
      <Wrapper container>
        <Grid gapFromL="XXL">
          <Grid.Item spanFromL={7}>
            <FullVideo />
          </Grid.Item>
          <Grid.Item spanFromL={5}>
            <H size="XL" margin={[0, 0, 'L']}>
              We’ll find your best job yet
            </H>
            <StyledP>
              We match design, technology and product experts with jobs they’ll
              love.
            </StyledP>
            <Button.Primary tag={Link} to={PATHS.JOBS} stretch>
              Start your search
            </Button.Primary>
          </Grid.Item>
        </Grid>
      </Wrapper>
    </StyledHeroWrapper>
  )
}

export default Home
