import React, { useEffect } from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { H, Wrapper } from 'components/common'
import { GTR } from 'styles/tokens'
import { screenMax } from 'styles/helpers/responsive'

import spotify from 'images/companies/spotify-logo.png'
import toyota from 'images/companies/toyota-logo.png'
import cazoo from 'images/companies/cazoo-logo.png'
import google from 'images/companies/google-logo.png'
import itechmedia from 'images/companies/itech-media-logo.png'
import schibsted from 'images/companies/schibsted-logo.png'

const IMAGES = [
  {
    alt: 'Schibstead',
    src: schibsted,
    width: 130,
  },
  {
    alt: 'iTech Media',
    src: itechmedia,
    width: 140,
  },
  {
    alt: 'Spotify',
    src: spotify,
    width: 120,
  },
  {
    alt: 'Google',
    src: google,
    width: 100,
  },
  {
    alt: 'Cazoo',
    src: cazoo,
    width: 120,
  },
  {
    alt: 'Toyota',
    src: toyota,
    width: 120,
  },
]

const StyledImageWrapper = styled(motion.ul)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-content: center;

  ${screenMax.s`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const StyledListItem = styled(motion.li)`
  padding: 0 ${GTR.M};
  text-align: center;

  ${screenMax.m`
    padding: ${GTR.S};
  `}

  ${screenMax.s`
    padding: ${GTR.S} ${GTR.M};
  `}
`

const StyledImage = styled.img`
  flex-shrink: 1;
  max-width: 100%;
`

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const Companies = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <Wrapper container>
      <H size="L" centered marginFromL={[0, 0, 'XXL']}>
        Companies we work with
      </H>
      <StyledImageWrapper
        variants={container}
        initial="hidden"
        animate={controls}
        ref={ref}
      >
        {IMAGES.map(({ alt, src, width }) => {
          return (
            <StyledListItem key={alt} variants={item}>
              <StyledImage src={src} alt={alt} width={width} />
            </StyledListItem>
          )
        })}
      </StyledImageWrapper>
    </Wrapper>
  )
}

export default Companies
