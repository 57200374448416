import React from 'react'
import VimeoVideo from 'components/VimeoVideo'
import { graphql, useStaticQuery } from 'gatsby'

const FullVideo = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: { eq: "ajay-and-team-at-whiteboard-short.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <VimeoVideo
      src="https://player.vimeo.com/video/414903226?app_id=122963&background=1"
      title="Building Relationships that last"
      autoPlay
      thumbnail={image}
    />
  )
}

export default FullVideo
