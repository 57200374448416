import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import CurveWrapper from 'components/CurveWrapper'
import VimeoVideo from 'components/VimeoVideo'
import { H, Grid, Wrapper } from 'components/common'
import { COLOR } from 'styles/tokens'

const Video = () => {
  const { melanie } = useStaticQuery(graphql`
    query {
      melanie: file(relativePath: { eq: "melanie-yenckin.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Wrapper container containerPaddingBottom="0">
        <Grid>
          <Grid.Item spanFromL={6}>
            <VimeoVideo
              src="https://player.vimeo.com/video/322232657?app_id=122963&autoplay=1"
              title="Building Relationships that last"
              thumbnail={melanie}
            />
          </Grid.Item>
          <Grid.Item spanFromL={5} startColumnFromL={8}>
            <H size="M" margin={[0, 0, 'L']} maxWidth={410}>
              There’s more to tech than computers
            </H>
            <Wrapper margin={[0, 0, 'L']}>
              <p>
                No matter the role, a human approach is key to everything we do.
                We’re finding ambitious people for forward thinking companies, a
                CV is just the beginning.
              </p>
            </Wrapper>
            <H size="S">Perfect jobs aren’t down to luck</H>
            <p>
              Great placements are a mixture of timing and expertise. We work
              with our candidates to prepare them for upcoming opportunities,
              allowing them to grow and accomplish goals they set for themselves
            </p>
          </Grid.Item>
        </Grid>
      </Wrapper>
      <CurveWrapper.Standalone background={COLOR.WHITE} />
    </>
  )
}

export default Video
